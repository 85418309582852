*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root{
    /* --mainColor: #29335C;
    --mainColorLight: #5767aa; */
    --mainColor:  #002e00;
    -mainColorLight:  #002e00;
    /* --secondaryColor: #db2b39; */
    --secondaryColor: #418626;
    --textColor: #eee;
}

.admin_header{
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    /* padding: 0 2rem; */
    background-color: var(--mainColor);
    color: var(--textColor);
    z-index: 200;
}
.admin_nav{
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin_nav a{
    margin: 0 2rem;
    color: var(--textColor);
    text-decoration: none;
    font-size: 1rem;
    
} 

.admin_nav a:hover {
color: var(--secondaryColor);

}

.admin_header .admin_nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    /* visibility: hidden;
    opacity: 0; */
    display: none;
    font-size: 1.8rem;
    

}
.admin_logout_big_screen{

    text-decoration: none;
    color: #fff;
    margin-right: 20px;
}
.admin_logout_small_screen{
    text-decoration: none;
    display: none;
    color: #fff;
}
.admin_logout_big_screen:hover, .admin_logout_small_screen:hover{
    color: var(--secondaryColor);
} 

@media only screen and (max-width: 1024px){
    .admin_header .admin_nav-btn{
        /* visibility: visible;
        opacity: 1; */
        display: block;
        margin-right: 1rem;
    }
    .admin_logout_big_screen{
        display: none;
    }
    .admin_logout_small_screen{
        display: block;
    }
    .admin_header .admin_nav{
        position: fixed;
        top: 0;
        left: 0;
        /* right: 0; */

         height: 100%;
        width: 100%; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
         /* gap: 1.5rem;  */
         gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
        transform: translateY(-100vh);
        z-index: 200;
    }

    .admin_header .admin_responsive_nav{
        transform: none; 
        z-index: 200;
    }
    .admin_nav .admin_nav-close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
        

    }

    .admin_nav a{
        font-size: 1rem;
    }

}


.admin_date_range div{
    display: inline-block;
}
.admin_date_range{
    text-align: center;
}

.admin_date_range button:hover{
    border: 2px solid #005200;
    
    background-color: #005200;
}
.admin_rep_date_lbl{
    font-size: 18px;
    font-weight: 400;
    margin-right: 10px;
    margin-bottom: 20px;
    color:#002e00; 
    
}

.admin_input_date:focus{
     /* outline: none;  */
     border: 1px solid #666;  
    
   
    
}
.admin_lbl{
    position: absolute;
    top: -10px;
    left: 15px;
    background: #fff;
    color: #002e00;
    font-weight: 600;
    font-size: 13px;
    z-index: 12;
}
@media only screen and (max-width: 1000px){
   
}

@media only screen and (max-width: 1000px){
    .admin_date_range{
        width: 100%;
    }
    .admin_rep_date_spn{
        margin-bottom: 20px;
    }
  
 .admin_date_range div{
            display: block;
        }
.admin_date_range div button{
           width: 200px;
        }
       
        
}
.admin_progress_container{
    display: flex;
    align-items: center;
    justify-content: center;
   
    height: 25px;
}
.admin_progress-bar-horizontal{
    border-radius: 5px; 
}
.admin_progress-bar-horizontal .admin_progress-bar {
    width: 0;
    height: 100%;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
     /* background-color: #469C30;  */
     background-color: #0000CD; 
     border-radius: 5px; 
    
  }
  .admin_progress-bar-horizontal {
    height: 10px;
    /* min-height: 400px; */
    margin-right: 10px!important;
    margin-left: 10px;
    /* background: #d0cece; */
     background: #798078!important; 
    /* background-image: linear-gradient(180deg, #444943, #808A7E); */
    display: -webkit-box;  
    display: -ms-flexbox;  
    display: -webkit-flex; 
    display: flex;        
    align-items: flex-start;
    -webkit-align-items: flex-start;
  }

  .admin_progress_timer_container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

@media screen and (max-width: 1000px) 
{
    .admin_progress-bar-horizontal{
        width: 140px;
    }
    .admin_rep_game_stat{
        padding: 1rem 0.7rem;
        margin: -3rem 1rem 1rem 1rem; 
    }
    .admin_game_number{
        margin-bottom: 30px;
    }
    .admin_count_bets{

    }
    .admin_count_bets_small{
        /* width: 100%;   */
        margin: 1rem 1rem; 
        padding: 1rem 1rem; 
       
    }
    .admin_factory_default{

    }
    .admin_factory_default_small{
        /* width: 100%;   */
        margin: 1rem 1rem; 
        padding: 1rem 1rem; 
       
    }
    .admin_rep_level{
        padding: 1rem 1rem;
         margin: -3rem 1rem 1rem 1rem ;
    }
    .admin_game_length{
        padding: 1rem 1rem;
         margin: 1rem 1rem 1rem 1rem ;
    }
    
        .admin_rep{
            margin: 1rem 1rem;
            padding: 1rem 1rem;
        }
        .admin_input_date{
            padding: 5px 10px;
            width: 200px;
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 20px;
        }
        .slc_search_criterea{
            
            width: 200px;
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 20px;
        }
        .admin_dashboards_dv {
             padding: 1rem 0rem; 
             
            /* background: #fff;
            border-radius: 5px; */
            /* background: red;  */
            }
            .admin_dashboards{
                margin: 0px 0rem;
                padding: 1rem 1rem;
             background: #fff;
            border-radius: 5px; 
            font-size: 18px;
            /* box-shadow: 5px 10px 18px #888888; */
            display: flex;
            align-items: center;
            justify-content: space-between;
            }
            
            .admin_date_range button {
                padding: 5px 10px;
                color: #fff;
                border: 2px solid #002e00;
                
                background-color: #002e00;
                border-radius: 5px;
                margin-left: 0px; 
                margin-right: 0px;
                width: 200px;
            }
            .admin_people_new_row{
                
                margin-bottom: 10px;
                
            }
            .admin_people_new_td_left
            {   
               margin-bottom: 3px; 
               text-align: center;   
    
            }
            .admin_people_new_td_right
            {
                text-align: center; 
                  
            }
           
}
@media screen  and (min-width: 1000px)
{
    .admin_progress-bar-horizontal{
        width: 300px;
        height: 15px;
    }
    .admin_rep_game_stat{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 2rem 2rem;
        margin: -3rem 2rem 1rem 2rem;
    }
    .admin_count_bets {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .admin_count_bets_small{
        width: 48%;  
        margin: 2rem 2rem; 
        padding: 2rem 2rem; 
       
    }
    .admin_factory_default {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .admin_factory_default_small{
        width: 48%;  
        margin: 2rem 2rem; 
        padding: 2rem 2rem; 
       
    }
    .admin_rep_level{
        padding: 2rem 2rem;
         margin: -3rem 2rem 2rem 2rem ;
    }
    .admin_game_length{
        padding: 2rem 2rem;
        margin: 2rem 2rem 2rem 2rem ;
    }
   
        .admin_rep{
            margin: 1rem 2rem;
            padding: 2rem 2rem;
        }
        .admin_input_date{
            padding: 5px 10px;
            width: 150px;
            margin-right: 5px;
            margin-left: 5px;
            margin-bottom: 20px;
        }
        .slc_search_criterea{
            
            margin-right: 5px;
            margin-left: 5px;
            margin-bottom: 20px;
        }
        .admin_dashboards_dv {
            padding: 2rem 1rem;
            
            /* background: #fff;
            border-radius: 5px; */
            /* background: red;  */
            }
            .admin_dashboards {
                margin: 0px 1rem;
                padding: 2rem 2rem;
             background: #fff;
            border-radius: 5px; 
            font-size: 18px;
            /* box-shadow: 5px 10px 18px #888888; */
            display: flex;
            align-items: center;
            justify-content: space-between;
            }
           
            .admin_date_range button {
                padding: 5px 10px;
                color: #fff;
                border: 2px solid #002e00;
                
                background-color: #002e00;
                border-radius: 5px;
                margin-left: 5px; 
                margin-right: 5px;
                width: 200px;
            }
            .admin_people_new_row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                
            }
            .admin_people_new_td_left
            {
                     width: 48%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-right: 5px;
                    
                   
            }
            .admin_people_new_td_right
            {
                width: 48%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                    margin-left: 5px;
            }
}
.admin_people_new_row span{
    min-width: 140px;
}
.admin_people_new input[type = "text"], .admin_people_new input[type = "password"]{
    border: 1px solid #999!important;
    border-radius: 5px;
    padding: 1px 5px;
}
.admin_people_new input[type = "text"]:focus, .admin_people_new input[type = "password"]{
    outline: none; 
     border: 1px solid #666!important; 
    
    /* box-shadow: 5px 10px 18px #888888; */
}

.admin_button_container div button{
    width: 90%;
    padding: 5px 10px;
    margin: 5px 4%;
    border: 1px solid grey;
    border-radius: 5px;
}
.admin_btn_status_change {
    padding: 5px 10px;
    border: 1px solid grey;
    border-radius: 5px;
}

.admin_btn_status_change:hover{
    background: #ccc4c4;
}
.admin_btn_people_menu{
    padding: 2px 5px;
    border: 1px solid grey;
    border-radius: 5px;
    margin-right: 10px;
}
.admin_btn_people_menu:hover{
    background: #ccc4c4;
}
.admin_tbl_people td{
    text-align: center;
}
.admin_tbl_people th{
    text-align: center;
}
.admin_tbl_people .spn_edit_del:hover{
    cursor: pointer;
}
.admin_tbl_add_people td{
    padding: 5px 10px;
}

tbody{
     border-width: 0px!important; 
   
}
thead tr{
    border-bottom: 2px solid #005200;
}

.card {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
    border: none;
  }
  
  .card-6 {
    background: transparent;
  }
  
  .card-6 .card-heading {
    background: transparent;
  }
  
  .card-6 .card-body {
    background: #fff;
    position: relative;
    border: 1px solid #e5e5e5;
    border-bottom: none;
    padding: 30px 0;
    padding-bottom: 0;
    -webkit-border-top-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    border-top-right-radius: 3px;
  }
  
  .card-6 .card-body:before {
    bottom: 100%;
    left: 75px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 10px;
  }
  
  .card-6 .card-footer {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-top: none;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-left-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    border-bottom-right-radius: 3px;
    padding: 50px 55px;
  }

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
    .login100-form {
      padding: 43px 15px 57px 117px;
    }
    
  }
  @media (max-width: 650px) {
    .dv_page_setup_mid{
      margin-bottom: 20px;
    }
  }
  @media (min-width: 650px) {
    .dv_page_setup {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
  }

  .dv_page_setup_mid{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dv_page_setup_small{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-left: 10px;
  }
  .dv_page_setup_small label{
    margin-right: 10px;
    font-size : 14px;
    color: #666;
  }

  .previous:hover, .next:hover {
    /* background-color: #ddd; */
    background-color: #005200;
    /* color: black; */
     color: #fff; 
    
  }

  .dv_page_setup_small a{
    text-decoration: none;
  display: inline-block;
  padding: 2px 10px;
  margin-right: 10px;
  }
  
  .previous {
    /* background-color: #f1f1f1; */
    /* background-color: gray; */
    color: #fff;
    cursor: pointer;
    background-color: #002e00;
  }
  
  .next {
/* background-color: gray; */
    color: white;
    cursor: pointer;
    background-color: #002e00;
    
  }
  .dull_lnk {
    background-color: gray;
    color: #fff;
  }
  .dull_lnk:hover{
    color: #fff;
    cursor: default;
  }