.calculator {
    display: flex;
    flex-direction: column;
    width: 500px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin: 0 auto;
  }
  
  .display {
    background-color: #eee;
    padding: 10px;
    font-size: 18px;
    text-align: right;
  }

  .bottom_buttons{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .bottom_buttons button {
    background-color: gainsboro;
    width: 25%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .buttons button {
    background-color: orange;
    width: 25%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
  }
  
  .buttons button:hover {
    background-color: #ddd;
  }