@import url(https://db.onlinewebfonts.com/c/16cc3b1da1e783d7e3504a73f3c8b535?family=DDT+W00+Expanded+Heavy);
@import url(https://db.onlinewebfonts.com/c/c108d9f65bbf96412a067336eb5934d3?family=Good+Timing+W00+Regular);
@font-face {
  font-family: "Rationell ExtraBlack";
  src: url("../../../assets/Keno/rationell-extrabold.otf");
}
@font-face {
  font-family: "Eurostile Next LT Com Semibold Font";
  src: url("../../../resources/Keno/css/fonts/Eurostile\ Next\ LT\ Com\ Semibold.ttf");
}

@font-face {
  font-family: "Gomme Sans";
  src: url("../../../resources/Keno/css/fonts/Gomme\ Sans.ttf");
}

@font-face {
  font-family: "Rehn";
  src: url("../../../resources/Keno/css/fonts/rehn.ttf");
}


.dv_main_container {
  overflow: hidden;
  /* margin-top: -4%; */
  /* padding: 0px!important; */
  font-family: roboto-regular;
}

.dv_internal_container {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: start;
  height: 100vh;
  /* height: 100%;   */

  /* padding-top: 1em; */
  margin: 0px !important;
}
.dv_balls_video {
  height: 100vh;
  /* height: 100%;   */
  /* background: yellow; */
  /* padding-top: 1em; */
  margin: 0px !important;
  /* background-image: url(../img/bottle_bg.jpg);  */
  /* background: #da1403; */
  background-image: url(../../Keno/img/green_bg6.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.dv_bottle_bg {
  height: 100vh;
  /* height: 100%;   */
  /* background: yellow; */
  /* padding-top: 1em; */
  margin: 0px !important;
  background-image: url(../../Keno/img/green_bg6.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background: #da1403; */
  position: relative;
}
.dv_animation_full_screen {
  /* margin-top: -60px; */
  width: 100%;
  height: 100vh;
}

.dv_spinner_and_progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: -30px; */
}
.dv_number_and_timer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.image-wrap {
  max-width: 500px;
  margin: auto;
  overflow: hidden;
  /* border: 5px solid red; */
}
.images {
  display: flex;
  margin: auto;
  padding: 0;
  list-style: none;
  text-align: center;
  /* animation: slide 48s steps(12) forwards infinite; */
}
@keyframes slide {
  to {
    transform: translateX(-950%);
  }
}
.images .dv_stat_container {
  font-family: Michroma;
  width: 100%;
  /* flex: 1 0 33.3333%; */
  min-width: 100%;
  /* border: 1px solid red; */
  /* padding: 10px; */
  height: 55vh;
  /* background: blue; */
  /* border: 3px solid gold; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.images .dv_tbl_container {
  /* width: 100%; */
  height: 500px;
  padding: 20px;
  /* animation: fade 4s ease-in-out infinite alternate; */
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 127;
  opacity: 0.4;
  /* background: transparent; */
}

/*-----------------------------------------------------------------*/
.slide_in_out {
  height: 65px;
  width: 65px;

  /* border-radius: 50%; */
  margin-right: 10px;
  margin-left: 10px;

  margin: 2px;
  font-weight: bolder;

  /* transition: transform 1s; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide_in {
  /* animation: slideIn .7s ease-in;  */
  transform: translateY(0px);
}
.slide_out {
  /* animation: slideOut 0.7s ease-in;  */
  transform: translateY(-500px);
}

@keyframes slideIn {
  0% {
    transform: translateY(-500px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes slideOut {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-500px);
  }
}
.slide_in_out:nth-child(2) {
  /* animation-delay: 1s; */
  transition-delay: 1s;
}
.slide_in_out:nth-child(1) {
  /* animation-delay: 2s; */
  transition-delay: 2s;
}
/* .slide_in_out:nth-child(2){ */
/* animation-delay: 2s; */
/* } */
/* .slide_in_out:nth-child(1){ */
/* animation-delay: 1s; */
/* } */

.dv_circles_container {
  padding-top: 30px;
  margin-left: -70px;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  height: 100vh;
  width: 100vh;
  background-image: linear-gradient(180deg, darkred, black);
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 1);
}
.dv_number_grid_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
}

.dv_numbers_container {
  height: 100%;
  /* display: flex;
  align-items: end;
  justify-content: center; */

  /* margin-right: 20px; */
  padding-bottom: 50px;
}
.dv_numbers {
  display: inline-block;
  /* margin-right: 30px; */
  /* margin-bottom: 40px; */
  /* border: 2px solid black; */
  background: rgba(255, 255, 255, 0.2);
  /* background: rgba(0, 0, 0, 0.8); */
  /* backdrop-filter: saturate(180%) blur(10px); */
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: 2px solid rgba(0, 0, 0, 0.4);
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-top: 1px solid rgba(0, 0, 0, 0.4);

  background: linear-gradient(
    to right,
    #000,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent,
    #000
  );
  /* #b86b77 */
  /* width: 200px; */
  /* height: 300px; */
  /* border-radius: 53% 47% 49% 51% / 2% 2% 2% 2%; */
}
.dv_golden_circle {
  width: 220px;
  height: 220px;
  background-image: url(../../Keno/img/golden_circle.png);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spn_golden_circle {
  animation: blinker 2s linear infinite;
  margin-right: 10px;
  margin-bottom: 10px;
}
.dv_drawing {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 554px;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  color: yellow;
  /* text-shadow: -1px 1px 0 gold, 1px 1px 0 gold, 1px -1px 0 gold,
    -1px -1px 0 gold; */
  /* text-shadow: -1px 1px 0 blue, 1px 1px 0 blue, 1px -1px 0 blue,
    -1px -1px 0 blue; */
}
.dv_current_game {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: bold;
  padding: 5px;
  /* color: red;
  text-shadow: -1px 1px 0 gold, 1px 1px 0 gold, 1px -1px 0 gold,
    -1px -1px 0 gold; */
  /* color: yellow; */
  color: linear-gradient(to bottom, #fbea00, #8b8300);
  /* text-shadow: -1px 1px 0 blue, 1px 1px 0 blue, 1px -1px 0 blue,
    -1px -1px 0 blue; */
}

.spn_time_remaining {
  /* color: yellow; */
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  font-family: Michroma;
  /* background-image: linear-gradient(to bottom, #fff65e, #a4832f);  */
  -webkit-background-clip: text; /* Clip the background to the text */
  -webkit-text-fill-color: transparent; /* Set the text color to transparent */
}
.draw-style {
  /* color: linear-gradient(to bottom, #c92727, #000); Set the gradient background */
}
.dv_time_remaining {
  /* margin-top: 10px; */
  /* width: 554px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-size: 28px; */
  /* font-weight: bold; */
  /* margin-bottom: 10px; */
  margin-top: 20px;
  /* font-weight: bold; */

  /* color: red;
  text-shadow: -1px 1px 0 gold, 1px 1px 0 gold, 1px -1px 0 gold,
    -1px -1px 0 gold; */
  color: yellow;
  /* text-shadow: -2px 2px 0 black, 2px 2px 0 black, 2px -2px 0 black,
    -2px -2px 0 black; */
}
.dv_evens {
  /* margin-top: 10px; */
  /* width: 554px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-size: 28px; */
  /* font-weight: bold; */
  /* margin-bottom: 10px; */
  /* margin-top: 20px; */
  /* font-weight: bold; */

  /* color: red;
  text-shadow: -1px 1px 0 gold, 1px 1px 0 gold, 1px -1px 0 gold,
    -1px -1px 0 gold; */
  color: yellow;
  /* text-shadow: -2px 2px 0 black, 2px 2px 0 black, 2px -2px 0 black,
    -2px -2px 0 black; */
}

@media screen and (max-width: 1300px) {
  .dv_balls_normal {
    display: inline-block;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    /* background: yellow; */
    /* margin-left: 71%; */
    position: absolute;
    right: 175px;
  }
  .img_bottle {
    top: 0px;
    width: 600px;
    height: 100vh;
    position: fixed;
    right: 50px;
    z-index: 1000;
  }

  .dv_number_grid_container {
    min-width: 900px;
  }

  .dv_tbl_container {
    width: 270px;
  }

  .dv_row div {
    width: 50px;
    height: 45px;
    /* background: blue; */

    /* border: 1px solid gold; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    font-size: 50px;
    font-weight: bold;
    /* text-shadow: -2px 2px 0 #000,
               2px 2px 0 #000,
              2px -2px 0 #000,
             -2px -2px 0 #000; */
  }
  .dv_time_remaining_game_number {
    font-family: "Rationell ExtraBlack";
    font-size: 70px;
  }
  .dv_time_remaining,
  .dv_evens {
    width: 700px;
  }
  @keyframes go_up {
    0% {
      transform: translate(0px, 0px) scale(1);
    }
    5% {
      transform: translate(0px, -250px) scale(1);
    }
    10% {
      transform: translate(0px, calc(0px - calc(50vh + 65px))) scale(1);
      margin-top: -60px;
    }
    15% {
      transform: translate(5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    20% {
      transform: translate(-5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    25% {
      transform: translate(5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    30% {
      transform: translate(-5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    35% {
      transform: translate(5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    40% {
      transform: translate(-5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    45% {
      transform: translate(5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    50% {
      transform: translate(-5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    55% {
      transform: translate(5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    60% {
      transform: translate(-5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    65% {
      transform: translate(5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    60% {
      transform: translate(-5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    75% {
      transform: translate(5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    80% {
      transform: translate(-5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    85% {
      transform: translate(5px, calc(0px - calc(50vh + 65px))) scale(2.3);
      margin-top: -60px;
    }
    90% {
      transform: translate(0px, calc(0px - calc(50vh + 65px))) scale(1);
      margin-top: -60px;
    }
    95% {
      transform: translate(0px, -800px) scale(1);
    }
    100% {
      transform: translate(0px, -1000px) scale(1);
    }
  }
}
@media screen and (max-width: 1500px) and (min-width: 1400px) {
  .dv_balls_normal {
    display: inline-block;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    /* background: yellow; */
    /* margin-left: 71%; */
    position: absolute;
    right: 175px;
  }
  .img_bottle {
    top: 0px;
    width: 600px;
    height: 100vh;
    position: fixed;
    right: 50px;
    z-index: 1000;
  }

  .dv_number_grid_container {
    min-width: 70%;
  }

  .dv_tbl_container {
    width: 80%;
  }
  .dv_row div {
    width: 65px;
    height: 60px;
    /* background: blue; */
    /* border: 1px solid gold; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    margin-bottom: 6px;
    border-radius: 10px;
    font-size: 35px;
    font-weight: bold;
    /* text-shadow: -2px 2px 0 #000,
               2px 2px 0 #000,
              2px -2px 0 #000,
             -2px -2px 0 #000; */
  }
  .dv_time_remaining,
  .dv_evens {
    width: 780px;
  }
  @keyframes go_up {
    0% {
      transform: translate(0px, 0px) scale(1);
    }
    5% {
      transform: translate(0px, -250px) scale(1);
    }
    10% {
      transform: translate(0px, calc(0px - calc(50vh + 80px))) scale(1);
      margin-top: -60px;
    }
    15% {
      transform: translate(5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    20% {
      transform: translate(-5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    25% {
      transform: translate(5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    30% {
      transform: translate(-5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    35% {
      transform: translate(5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    40% {
      transform: translate(-5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    45% {
      transform: translate(5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    50% {
      transform: translate(-5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    55% {
      transform: translate(5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    60% {
      transform: translate(-5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    65% {
      transform: translate(5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    60% {
      transform: translate(-5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    75% {
      transform: translate(5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    80% {
      transform: translate(-5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    85% {
      transform: translate(5px, calc(0px - calc(50vh + 80px))) scale(2.3);
      margin-top: -60px;
    }
    90% {
      transform: translate(0px, calc(0px - calc(50vh + 80px))) scale(1);
      margin-top: -60px;
    }
    95% {
      transform: translate(0px, -800px) scale(1);
    }
    100% {
      transform: translate(0px, -1000px) scale(1);
    }
  }
}
@media screen and (max-width: 2000px) and (min-width: 1500px) {
  .dv_balls_normal {
    /* text-align: center; */
    /* padding-top: 80px; */
    display: inline-block;
    border-radius: 50%;
    height: 180px;
    width: 180px;
    /* background: yellow; */
    /* margin-left: 71%; */
    position: absolute;
    right: 210px;
  }
  .img_bottle {
    top: 0px;
    width: 600px;
    height: 100vh;
    position: fixed;
    right: 50px;
    z-index: 1000;
  }
 
  .dv_number_grid_container {
    min-width: 70%;
  }

  .dv_tbl_container {
    width: 80%;
  }
  .dv_row div {
    width: 95px;
    height: 70px;
    /* background: blue; */
    /* border: 1px solid gold; */
    /* border: 1px solid transparent; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    margin-bottom: 7px;
    /* color: gold; */

    border-radius: 25px;
    font-size: 65px;
    font-weight: bold;
    /* text-shadow: -2px 2px 0 #000,
               2px 2px 0 #000,
              2px -2px 0 #000,
             -2px -2px 0 #000; */
  }
  .dv_time_remaining,
  .dv_evens {
    width: 1020px;
  }

  @keyframes go_up {
    0% {
      transform: translate(0px, 0px) scale(1);
    }
    5% {
      transform: translate(0px, -250px) scale(1);
    }
    10% {
      transform: translate(0px, calc(0px - calc(50vh + 95px))) scale(1);
      margin-top: -60px;
    }
    15% {
      transform: translate(5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    20% {
      transform: translate(-5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    25% {
      transform: translate(5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    30% {
      transform: translate(-5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    35% {
      transform: translate(5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    40% {
      transform: translate(-5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    45% {
      transform: translate(5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    50% {
      transform: translate(-5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    55% {
      transform: translate(5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    60% {
      transform: translate(-5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    65% {
      transform: translate(5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    60% {
      transform: translate(-5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    75% {
      transform: translate(5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    80% {
      transform: translate(-5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    85% {
      transform: translate(5px, calc(0px - calc(50vh + 95px))) scale(2.3);
      margin-top: -60px;
    }
    90% {
      transform: translate(0px, calc(0px - calc(50vh + 95px))) scale(1);
      margin-top: -60px;
    }
    95% {
      transform: translate(0px, -800px) scale(1);
    }
    100% {
      transform: translate(0px, -1000px) scale(1);
    }
  }
}
@media screen and (max-width: 3000px) and (min-width: 2000px) {
  .dv_balls_normal {
    display: inline-block;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    /* background: yellow; */
    /* margin-left: 71%; */
    position: absolute;
    right: 220px;
  }
  .img_bottle {
    top: 0px;
    width: 600px;
    height: 100vh;
    position: fixed;
    right: 50px;
    z-index: 1000;
  }
  
  .dv_number_grid_container {
    min-width: 70%;
  }

  .dv_tbl_container {
    width: 80%;
  }
  .dv_row div {
    width: 70px;
    height: 65px;
    /* background: blue; */
    /* border: 1px solid gold; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25x;

    font-size: 35px;
    font-weight: bold;
    margin-right: 8px;
    margin-bottom: 8px;
    /* text-shadow: -2px 2px 0 #000,
               2px 2px 0 #000,
              2px -2px 0 #000,
             -2px -2px 0 #000; */
  }
  .dv_time_remaining,
  .dv_evens {
    width: 820px;
  }

  @keyframes go_up {
    0% {
      transform: translate(0px, 0px) scale(1);
    }
    5% {
      transform: translate(0px, -250px) scale(1);
    }
    10% {
      transform: translate(0px, calc(0px - calc(50vh + 110px))) scale(1);
      margin-top: -60px;
    }
    15% {
      transform: translate(5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    20% {
      transform: translate(-5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    25% {
      transform: translate(5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    30% {
      transform: translate(-5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    35% {
      transform: translate(5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    40% {
      transform: translate(-5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    45% {
      transform: translate(5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    50% {
      transform: translate(-5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    55% {
      transform: translate(5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    60% {
      transform: translate(-5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    65% {
      transform: translate(5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    60% {
      transform: translate(-5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    75% {
      transform: translate(5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    80% {
      transform: translate(-5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    85% {
      transform: translate(5px, calc(0px - calc(50vh + 110px))) scale(2.3);
      margin-top: -60px;
    }
    90% {
      transform: translate(0px, calc(0px - calc(50vh + 80px))) scale(1);
      margin-top: -60px;
    }
    95% {
      transform: translate(0px, -800px) scale(1);
    }
    100% {
      transform: translate(0px, -1000px) scale(1);
    }
  }
}

@keyframes blinker {
  from {
    font-size: 35px;
  }
  /* 25% { font-size: 30px; } */
  50% {
    font-size: 50px;
  }
  /* 75% {font-size: 30px;} */
  to {
    font-size: 35px;
  }
}
.dv_row {
  display: flex;
  justify-content: start;
  align-items: center;
}
.dv_grid_animation {
  padding-left: 30px;
}

/*------------------------flipper-------------------------------*/

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 55vh;
  perspective: 1000px;
  margin-bottom: 10px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  /* height: 100%; */
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}

/* .flip-card:hover .flip-card-inner {
   transform: rotateY(180deg);
 } */

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  /* background-color: #bbb;
   color: black; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-size: 40px;
  /* text-shadow: -2px 2px 0 #000,
				  2px 2px 0 #000,
				 2px -2px 0 #000,
				-2px -2px 0 #000; */
  -webkit-text-stroke: 2px black;
}

.flip-card-back {
  /* background-color: #2980b9;
   color: white; */
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dv_tbl_container {
  /* background: blue; */
  /* background: darkred; */
  /* background: #c04000; */
  /* background-image: linear-gradient(180deg, #c04000, darkred); */
  min-height: 55vh;
  min-width: 260px;
  /* width: 80%; */
  /* border: 1px solid gold; */
  /* border-radius: 5px; */
}

.tbl_stats {
  width: 100%;
  /* color: gold; */
  /* background: blue; */
  font-weight: 600;
  font-family: waukegan;
}
.tbl_stats thead tr {
  border-bottom: 0px solid black;
}
.tbl_stats tr td,
th {
  padding: 0px;
}
/* .tbl_stats th {
  background: black;
} */
.th_picks {
  font-family: Anta;
  color: red;
  font-weight: 800;
  font-size: 58px;
  padding-left: 15%;
}
.tr_hits_win {
  color: yellow;
  font-size: 46px;
}
.tbl_stats td {
  font-size: 45px;
  color: white;
}
.tbl_stats th,
td {
  padding-top: 1px;
  padding-bottom: 1px;
}
td:nth-child(1) {
  text-align: left;
}
th:nth-child(1) {
  text-align: left;
}
td:nth-child(3) {
  text-align: right;
}
th:nth-child(3) {
  text-align: right;
}

/*-------------------------------------shuffle------------------------*/

h1,
p {
  text-align: center;
}
html {
  /* box-sizing: border-box; */
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

@keyframes slide {
  to {
    transform: translateX(-800%);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dv_marquee {
  width: 100%;
  height: 35px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 15;
  display: flex;
  align-items: end;
  justify-content: center;
  /* background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
   radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%); */
  /* background: blue;    */
  /* box-shadow: 5px 10px #888888; */
  /* padding-top: 5px; */
}
.dv_marquee marquee {
  /* height: 100%;   */
  /* margin: 0; */
  /* height: 35px; */
  /* background: red; */
  height: 35px;
  padding-top: 3px;
}
.spn_game_result {
  /* color: red; */
  font-weight: bold;
  /* -webkit-text-stroke: 1px WHITE; */

  font-size: 18px;
  color: #fff;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
}
.spn_result_numbers {
  display: inline-block;
  border-radius: 50%;
  min-width: 30px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  background: yellow;
  border: 1px solid black;
  font-size: 18px;
  padding-top: 1px;
  text-align: center;
  color: #fff;
  text-shadow: -1px 1px 0 black, 1px 1px 0 black, 1px -1px 0 black,
    -1px -1px 0 black;
}
.spn_result_numbers2 {
  display: inline-block;
  min-width: 27px;
  height: 27px;
  font-size: 18px;
  font-weight: bold;
  /* padding: 2px;  */
  background: gold;
  background: linear-gradient(
    to right,
    #bf953f,
    #fcf6ba,
    #b38728,
    #fbf5b7,
    #aa771c
  );
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
  color: blue;
  border: 1px solid blue;
  border-radius: 50%;
  padding-bottom: 3px;
  text-align: center;
}
.spn_time_main {
  font-weight: bold;
  font-size: 22px;
}
.dv_jackpot h1 {
  color: white;
  font-weight: bold;
  /* -webkit-text-stroke: 1px WHITE; */
  font-size: 18px;
  text-shadow: -4px 4px 0 gold, 4px 4px 0 gold, 4px -4px 0 gold,
    -4px -4px 0 gold;
}

.dv_balls_animated1 {
  animation: go_up 1s linear;
  animation-delay: 1s;
}
.dv_balls_animated2 {
  animation: go_up 1s linear;
  animation-delay: 4s;
}
.dv_balls_animated3 {
  animation: go_up 1s linear;
  animation-delay: 7s;
}
.dv_balls_animated4 {
  animation: go_up 1s linear;
  animation-delay: 10s;
}
.dv_balls_animated5 {
  animation: go_up 1s linear;
  animation-delay: 13s;
}
.dv_balls_animated6 {
  animation: go_up 1s linear;
  animation-delay: 16s;
}
.dv_balls_animated7 {
  animation: go_up 1s linear;
  animation-delay: 19s;
}
.dv_balls_animated8 {
  animation: go_up 1s linear;
  animation-delay: 22s;
}
.dv_balls_animated9 {
  animation: go_up 1s linear;
  animation-delay: 25s;
}
.dv_balls_animated10 {
  animation: go_up 1s linear;
  animation-delay: 28s;
}
.dv_balls_animated11 {
  animation: go_up 1s linear;
  animation-delay: 31s;
}
.dv_balls_animated12 {
  animation: go_up 1s linear;
  animation-delay: 34s;
}
.dv_balls_animated13 {
  animation: go_up 1s linear;
  animation-delay: 37s;
}
.dv_balls_animated14 {
  animation: go_up 1s linear;
  animation-delay: 40s;
}
.dv_balls_animated15 {
  animation: go_up 1s linear;
  animation-delay: 43s;
}
.dv_balls_animated16 {
  animation: go_up 1s linear;
  animation-delay: 46s;
}
.dv_balls_animated17 {
  animation: go_up 1s linear;
  animation-delay: 49s;
}
.dv_balls_animated18 {
  animation: go_up 1s linear;
  animation-delay: 52s;
}
.dv_balls_animated19 {
  animation: go_up 1s linear;
  animation-delay: 55s;
}
.dv_balls_animated20 {
  animation: go_up 1s linear;
  animation-delay: 58s;
}
.img-shuffled-number-ball {
  width: 250px;
  height: "90%";
  margin-left: -60px;
  /* margin-top: -10px; */
}

/* @keyframes go_up {

 from { transform: translateY(0px)  scale(1);}
 to {transform: translateY(-1000px) scale(2);}
 
} */

@keyframes tilt-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.dv_grid_cells_selected {
  /* background-color: #C04000; */
  background-image: linear-gradient(#c04000, darkred);
  color: red;
  border-radius: 25px;
  font-family: waukegan;
  /* transition-property: background-color, color;
   transition-duration: .2s;
   transition-timing-function: linear; */
  animation: bg_animation 0.1s forwards;
  animation-delay: 1s;
  font-size: 40px;
}
.dv_grid_cells_normal {
  /* background-color: #C04000; */
  background-image: linear-gradient(#D90803, #970102);
  color: red;
  font-family: waukegan;
  border-radius: 25px;
  font-size: 40px;
  margin-left: -1px;
  margin-top: -1px;
  /* transition-property: background-color, color;
    transition-duration: .2s;
    transition-timing-function: linear; */
}

.dv_grid_cells_animated {
  /* animation: bg_animation 0.1s linear;
    animation-delay: 1s; */
  /* background-color: yellow;
    -webkit-transition-delay:1s;
    transition-delay:1s; */
  /* transition-delay: 1s; */
  animation: bg_animation 0.1s forwards;
  animation-delay: 1s;
}
.dv_grid_cells_animated1 {
  /* animation: bg_animation 0.1s linear;
    animation-delay: 1s; */
  /* background-color: yellow;
    -webkit-transition-delay:1s;
    transition-delay:1s; */
  /* transition-delay: 1s; */
  animation: bg_animation 0.1s forwards;
  /* animation-delay: 1s; */
  animation-delay: 0s;
}
.dv_grid_cells_animated2 {
  /* animation: bg_animation 0.1s linear;
    animation-delay: 4s; */
  /* background-color: yellow;
    -webkit-transition-delay:4s;
    transition-delay:4s; */

  /* IE 10+ */
  animation: bg_animation 0.1s forwards;
  animation-delay: 3s;
}
.dv_grid_cells_animated3 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 6s;
  /* background-color: yellow;
    -webkit-transition-delay:7s;
    transition-delay:7s; */
}
.dv_grid_cells_animated4 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 9s;
  /* background-color: yellow;
    -webkit-transition-delay:10s;
    transition-delay:10s; */
}
.dv_grid_cells_animated5 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 12s;
  /* background-color: yellow;
    -webkit-transition-delay:13s;
    transition-delay:13s; */
}
.dv_grid_cells_animated6 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 15s;
  /* background-color: yellow;
    -webkit-transition-delay:16s;
    transition-delay:16s; */
}
.dv_grid_cells_animated7 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 18s;
  /* background-color: yellow;
    -webkit-transition-delay:19s;
    transition-delay:19s; */
}
.dv_grid_cells_animated8 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 21s;
  /* background-color: yellow;
    -webkit-transition-delay:22s;
    transition-delay:22s; */
}
.dv_grid_cells_animated9 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 24s;
  /* background-color: yellow;
    -webkit-transition-delay:25s;
    transition-delay:25s; */
}
.dv_grid_cells_animated10 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 27s;
  /* background-color: yellow;
    -webkit-transition-delay:28s;
    transition-delay:28s; */
}
.dv_grid_cells_animated11 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 30s;
  /* background-color: yellow;
    -webkit-transition-delay:31s;
    transition-delay:31s; */
}
.dv_grid_cells_animated12 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 33s;
  /* background-color: yellow;
    -webkit-transition-delay:34s;
    transition-delay:34s; */
}
.dv_grid_cells_animated13 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 36s;
  /* background-color: yellow;
    -webkit-transition-delay:37s;
    transition-delay:37s; */
}
.dv_grid_cells_animated14 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 39s;
  /* background-color: yellow;
    -webkit-transition-delay:40s;
    transition-delay:40s; */
}
.dv_grid_cells_animated15 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 42s;
  /* background-color: yellow;
    -webkit-transition-delay:43s;
    transition-delay:43s; */
}
.dv_grid_cells_animated16 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 45s;
  /* background-color: yellow;
    -webkit-transition-delay:46s;
    transition-delay:46s; */
}
.dv_grid_cells_animated17 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 48s;
  /* background-color: yellow;
    -webkit-transition-delay:49s;
    transition-delay:49s; */
}
.dv_grid_cells_animated18 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 51s;
  /* background-color: yellow;
    -webkit-transition-delay:52s;
    transition-delay:52s; */
}
.dv_grid_cells_animated19 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 54s;
  /* background-color: yellow;
    -webkit-transition-delay:55s;
    transition-delay:55s; */
}
.dv_grid_cells_animated20 {
  animation: bg_animation 0.1s forwards;
  animation-delay: 57s;

  /* background-color: yellow;
    -webkit-transition-delay:58s;
    transition-delay:58s; */
}


.dv_grid_gray_cells_animated {
  /* animation: bg_animation 0.1s linear;
    animation-delay: 1s; */
  /* background-color: yellow;
    -webkit-transition-delay:1s;
    transition-delay:1s; */
  /* transition-delay: 1s; */
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 0s;
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated1 {
  /* animation: bg_animation 0.1s linear;
    animation-delay: 1s; */
  /* background-color: yellow;
    -webkit-transition-delay:1s;
    transition-delay:1s; */
  /* transition-delay: 1s; */
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 1s;
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated2 {
  /* animation: bg_dark_animation 0.1s linear;
    animation-delay: 4s; */
  /* background-color: yellow;
    -webkit-transition-delay:4s;
    transition-delay:4s; */

  /* IE 10+ */
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 4s;
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated3 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 7s;
  /* background-color: yellow;
    -webkit-transition-delay:7s;
    transition-delay:7s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated4 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 10s;
  /* background-color: yellow;
    -webkit-transition-delay:10s;
    transition-delay:10s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated5 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 13s;
  /* background-color: yellow;
    -webkit-transition-delay:13s;
    transition-delay:13s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated6 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 16s;
  /* background-color: yellow;
    -webkit-transition-delay:16s;
    transition-delay:16s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated7 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 19s;
  /* background-color: yellow;
    -webkit-transition-delay:19s;
    transition-delay:19s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated8 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 22s;
  /* background-color: yellow;
    -webkit-transition-delay:22s;
    transition-delay:22s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated9 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 25s;
  /* background-color: yellow;
    -webkit-transition-delay:25s;
    transition-delay:25s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated10 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 28s;
  /* background-color: yellow;
    -webkit-transition-delay:28s;
    transition-delay:28s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated11 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 31s;
  /* background-color: yellow;
    -webkit-transition-delay:31s;
    transition-delay:31s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated12 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 34s;
  /* background-color: yellow;
    -webkit-transition-delay:34s;
    transition-delay:34s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated13 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 37s;
  /* background-color: yellow;
    -webkit-transition-delay:37s;
    transition-delay:37s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated14 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 40s;
  /* background-color: yellow;
    -webkit-transition-delay:40s;
    transition-delay:40s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated15 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 43s;
  /* background-color: yellow;
    -webkit-transition-delay:43s;
    transition-delay:43s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated16 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 46s;
  /* background-color: yellow;
    -webkit-transition-delay:46s;
    transition-delay:46s; */
  /*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated17 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 49s;
  /* background-color: yellow;
    -webkit-transition-delay:49s;
    transition-delay:49s; */
 /* border: 1px solid white;*/
}
.dv_grid_gray_cells_animated18 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 52s;
  /* background-color: yellow;
    -webkit-transition-delay:52s;
    transition-delay:52s; */
/*border: 1px solid white;*/
}
.dv_grid_gray_cells_animated19 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 55s;
  /* background-color: yellow;
    -webkit-transition-delay:55s;
    transition-delay:55s; */
 /* border: 1px solid white;*/
}
.dv_grid_gray_cells_animated20 {
  animation: bg_dark_animation 0.1s forwards;
  animation-delay: 58s;

  /* background-color: yellow;
    -webkit-transition-delay:58s;
    transition-delay:58s; */
 /*border: 1px solid white;*/
}


@keyframes bg_animation {
  /* 0%     {background-color:#C04000; color: red;}
    100%  {background-color:yellow; color: black} */
  0% {
    background-image: linear-gradient(#c04000, darkred);
    color: red;
  }
  100% {
    background-image: linear-gradient(yellow, #E6880E);
    color: black;
  }
}


@keyframes bg_dark_animation {
  /* 0%     {background-color:#C04000; color: red;}
    100%  {background-color:yellow; color: black} */
  0% {
    background-image: linear-gradient(#c04000, darkred);
    color: red;
  }
  100% {
    background-image: linear-gradient(#E6880E, #E6880E);
    color: black;
  }
}


/* @keyframes bg_animation {
  0%     {background-color:#C04000; color: red;}
  100%  {background-color:yellow; color: black}
  
} */

.dv_bg_test {
  display: inline-block;
  background-color: green;
  color: white;
  width: 100px;
  height: 100px;
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}
.dv_bg_test_animated {
  transition-delay: 4s;
  background-color: yellow;
  color: red;
}

.spn_draw {
  font-size: 100px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spn_evens {
  font-size: 35px;
  border-radius: 15px;
  width: 250px;
   font-family: "DDT W00 Expanded Heavy"; 
   font-weight: lighter;
  text-align: center;
  background-image: linear-gradient(grey, #fff);
  background-image: -ms-linear-gradient(
    top left,
    #707070 -50%,
    #fcfcfc 110.00000000000001%
  );
  padding: 3px 17px 3px 17px;
  color: black;
}
.dv_last_ten_draws {
  margin-top: 30px;
}
.dv_last_ten_row {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
}

.dv_last_ten_number {
  display: inline-block;
  font-size: 55px;
  color: #fff;
  font-weight: bolder;
  width: 8%;
  text-align: center;
  font-family: "Rationell ExtraBlack";
  font-family: waukegan;
  padding-top: 10px;
}
.dv_last_ten_only_numbers {
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dv_last_ten_tails,
.dv_last_ten_heads {
  display: inline-block;
  /* height: 40px;
  width: 40px; */
  /* width: 9%; */
  /* height: 0; */
  /* padding-bottom: 20%; */
  background: yellow;
  height: 65px;
  width: 70px;
  font-size: 30px;
  font-weight: 800;
  border-radius: 50%;
  text-align: center;
  padding-top: 0px;
  margin-right: 0.5%;
}
.dv_last_ten_tails {
  background: yellow;
}
.dv_last_ten_heads {
  background: #FFF427;
  font-family: waukegan;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  margin-bottom: 7px;
}
.dv_last_ten_heads_gray {
  background: #FFAE34;
  display: inline-block;
  /* height: 40px;
  width: 40px; */
  /* width: 9%; */
  /* height: 0; */
  /* padding-bottom: 20%; */
  height: 65px;
  width: 70px;
  font-size: 50px;
  font-weight: 800;
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  margin-right: 0.5%;
  font-family: waukegan;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}
.dv_tenth {
  border-right: 2px solid white;
}

.dv_out_of_twenty {
  display: inline-block;
  font-size: 44px;
  font-weight: 800;
  color: #fff;
  position: fixed;
  top: 60px;
  right: 40px;
  font-family: waukegan;
  margin-top: -17px;
  margin-left: -10px;
}
.spn_time_main2 {
  color: green;
  font-weight: bolder;
  font-size: larger;
  margin-top: -50px;
  font-family: "Gomme Sans";

}
.spn_time_main2_blink {
  animation: blinker 1s linear infinite;
  /* animation-delay: 0.3s; */
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.dv_pick_10_info {
  font-size: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Eurostile Next LT Com Semibold Font";
}
.dv_pick_10_info_red {
  color: red;
  font-family: "Good Timing W00 Regular";
}
.dv_pick_10_info_row {
  display: flex;
}
.img_keno_logo {
  width: 250px
}