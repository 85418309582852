/* QuickPickButton.css */
.quick-pick-container {
    width: 170px;
    margin: 10px;
    height: 55px;
  }
  
  .dropdown {
    display: inline-block;
    position: relative;
  }
  
  .quick-pick-btn {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 170px;
    height: 50px;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: max-content;
    margin-left: 80px;
    font-size: 18px;
    z-index: 1;
  }
  
  .dropdown-content button {
    display: block;
    padding: 10px;
    background-color: #f9f9f9;
    border: none;
    cursor: pointer;
    color: black; /* Set number color to black */
  }
  
  .dropdown-content button:hover {
    background-color: #ddd;
  }
  
  .dropdown.open .dropdown-content {
    display: block;
  }
  
  .dropdown.open .quick-pick-btn {
    background-color: #3e8e41;
  }
  .small-input {
    margin: 5px;
    width: 50px; /* Adjust width as needed */
    font-size: 18px; /* Adjust font size as needed */
  }
  