.pagination-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120%;
    text-align: center;
    font-size: 18px;
    border-spacing: 3px solid;
    border-collapse: collapse;
    /*border: 2px solid #fff;*/
     margin-top: 80px;
     margin-left: 120px;
  }
  
  .pagination {
    list-style-type: none;
    padding: 0;
    display: flex;
  }
  
  .pagination li {
    margin: 0 0px;
  }
  
  .pagination li.active a {
    font-weight: bold;
    color: white; /* Set text color to white */
    background-color: red;
  }
  
  .pagination li a {
    text-decoration: none;
    padding: 15px 15px;
    font-size: 22px;
    color: white; /* Set text color to white */
    border: 2px solid white; /* Add border with white color */
  }
  
  .pagination li a:hover {
    background-color: darkgoldenrod;
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
    }
  
  .page-link.green {
    background-color: green;
  }
  
  .page-link.red {
    background-color: red;
  }
  
  .page-link.black {
    background-color: black;
  }
  
  .page-link.active {
    background-color: gold ;
    font-weight: bold;
  }
  
  .arrow {
    border: none; /* Remove border */
    background-color: transparent; /* Set background color to transparent */
    font-size: 30px; /* Set font size */
    color: white; /* Set text color */
  }
  
  .arrow:hover {
    background-color: darkgoldenrod; /* Hover background color */
  }
  
  .left {
   
    margin-right: 15px; /* Adjust margin for spacing */
    margin-top: 10px;
   width: 20px;
   margin-left: 20px;
 
  }
  
  .right {
    margin-left: 15px; /* Adjust margin for spacing */
    margin-top: 10px;
    width: 20px;
    margin-right: -20px;
  }
  
  .carousel-pagination-container {
    width: 114%;
    margin-left: 86px;
    text-align: center;
    font-size: 18px;
    border-spacing: 3px solid;
    border-collapse: collapse;
    border: 2px solid #fff;
    height: 50px;
    padding-top: 20px;
  }
  